import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import './UserManagementPage.css'; // 引入样式文件
import CustomDialog from './CustomDialog';



const ITAUserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // 跟踪选中的用户
  const [selectedUser, setSelectedUser] = useState(null); // 用于编辑用户信息
  const [isEditing, setIsEditing] = useState(false); // 是否处于编辑模式
  const [isAdding, setIsAdding] = useState(false); // 是否处于新增模式
  const [page, setPage] = useState(0); // 当前页码，默认为第一页
  const [totalPages, setTotalPages] = useState(1); // 总页数，默认为1
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const usersCacheRef = useRef({}); // 使用 useRef 定义持久化引用

  useEffect(() => {
    fetchUsers();
  }, [searchTerm, page]); // 当页码改变时重新获取用户数据

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token'); // 从本地存储中获取token
      const response = await axios.get(`http://romi.one:8080/italy-visa/api/users?accountName=${searchTerm}&page=${page}&size=15`, {
        headers: {
          Authorization: token // 将token添加到请求头中
        }
      });
      setTotalPages(response.data.totalPages)
      setUsers(response.data.content);

      for (const user of response.data.content) {
        usersCacheRef[user['id']] = user
      }
      console.log(usersCacheRef)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1); // 下一页
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1); // 上一页
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  
  const handleStatus = async (user) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`http://romi.one:8080/italy-visa/api/users/appointments?uid=${user.id}`, {
        headers: {
          Authorization: token,
        },
      });

      if (response.data.length > 0) {
        let message = `用户 ${user.lastName} ${user.firstName} 的预约数量: ${response.data.length}\n\n`;

        response.data.forEach((appointment, index) => {
          const { bookDate, cancelStatus } = appointment;
          const formattedDate = formatDateDetail(bookDate);
          const statusText = cancelStatus === 0 ? "已预订" : "已取消";
          message += `预约 ${index + 1}:\n日期: ${formattedDate}\n状态: ${statusText}\n\n`;
        });

        setDialogContent(message);
        setDialogOpen(true);
      } else {
        setDialogContent('未找到该用户的预约信息。');
        setDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching status:', error);
      setDialogContent('获取状态时出错，请稍后再试。');
      setDialogOpen(true);
    }
  };
  

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveUser = async (updatedUser) => {
    try {
      if (isAdding) {
        // 新增用户
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        await axios.post(`http://romi.one:8080/italy-visa/api/users`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        // 新增成功，显示成功提示
        alert('添加客户成功');
      } else {
        const token = localStorage.getItem('token'); // 从本地存储中获取token
        const response = await axios.put(`http://romi.one:8080/italy-visa/api/users/${updatedUser.id}`, updatedUser, {
          headers: {
            Authorization: token // 将token添加到请求头中
          }
        });
        console.log('User updated:', response.data);
      }
      setIsEditing(false);
      setSelectedUser(null);
      setIsAdding(false);
      fetchUsers(); // 保存后刷新用户列表
    } catch (error) {
      console.error('Error saving user:', error);
      alert('Failed to save user. Please try again later.');
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedUser(null);
  };

  const handleCheckboxChange = (userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    if (selectedIndex === -1) {
      setSelectedUsers([...selectedUsers, userId]); // 添加到选中列表
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId)); // 从选中列表中移除
    }
  };

  const handleAddUser = () => {
    console.log('Adding a new user...');
    setIsAdding(true);
    setSelectedUser(
      {
        citizen: 'chinese',
        emailAccount: '',
        emailPassword: '',
        systemAccount: '',
        systemPassword: '',
        passport: '', phone: '',
        passportExpiryDate: '2034-01-01',
        irpPdf:'',
        passportPdf:'',
        expectedVisaSubmitDate: formatDate(new Date().toISOString()),
        expectTime: "09:30",
        status: 0,
        note: '',
        socialAccount:'',
        paymentAmount: ''
      }
    ); // 清空选中用户数据
    setIsEditing(true);
  };

  const handleExport = () => {
    // 将ISO日期转换为MM/DD/YYYY格式的函数
    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    };
    // 将原始数据转换为所需格式
    const exportedUsers = selectedUsers.map(userId => {
      const user = usersCacheRef[userId];
      return {
        id: user.id,
        enable: 1,
        birth_date: formatDate(user.birthday),
        email: user.email,
        phone: user.phone,
        first_name: user.firstName,
        last_name: user.lastName
      };
    });
  
    // 输出转换后的数据
    console.log('Exported users:', exportedUsers);

    const data = JSON.stringify(exportedUsers, null, 2);
  
    const blob = new Blob([data], { type: 'application/json' });
    saveAs(blob, 'data.json');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  function getStatusText(status) {
    switch (status) {
      case 0:
        return '未完成';
      case 1:
        return '已完成';
      case 2:
        return '已取消';
      default:
        return '未知状态';
    }
  }

  const formatDateDetail = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    const hours = date.getHours().toString().padStart(2, '0'); // 将小时补齐为两位数
    const minutes = date.getMinutes().toString().padStart(2, '0'); // 将分钟补齐为两位数
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleDownloadFile = (base64, fileName) => {
    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${base64}`;
    link.download = fileName;
    link.click();
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]; // 提取Base64字符串
            setSelectedUser(prevUser => ({
                ...prevUser,
                [field]: base64String, // 保存Base64格式
            }));

            // setFormData(prevData => ({
            //     ...prevData,
            //     [field]: base64String, // 同时更新表单数据
            // }));
        };
        reader.readAsDataURL(file);
    }
};

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <h2 className="title-style">ITA用户管理</h2>
      <div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by social account..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className="button-container">
        <button onClick={handleAddUser}>新增用户</button>
      </div>
    </div>
      {isEditing ? (
        <UserForm user={selectedUser} onSave={handleSaveUser} onCancel={handleCancelEdit} onFileChange={handleFileChange}/>
      ) : (
        <div>
          <table className="user-table"> {/* 添加类名 */}
            <thead>
              <tr>
                <th>选择</th> {/* 添加选中列 */}
                <th>ID</th>
                <th>社交账户</th>
                <th>地址</th>
                <th>国籍</th>
                <th>邮箱账户📮</th>
                <th>邮箱密码📮</th>
                <th>系统账户</th>
                <th>系统密码</th>
                <th>护照号</th>
                <th>护照过期时间📅</th>
                <th>电话📱</th>
                <th>期望递签日期📅</th>
                <th>irp文件</th>
                <th>护照文件</th>
                <th>订单状态</th>
                <th>支付金额💰</th>
                <th>备注📓</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(user.id)}
                      checked={selectedUsers.includes(user.id)}
                    />
                  </td>
                  <td>{user.id}</td>
                  <td>{user.socialAccount}</td>
                  <td>{user.address}</td>
                  <td>{user.citizen}</td>
                  <td>{user.emailAccount}</td>
                  <td>{user.emailPassword}</td>
                  <td>{user.systemAccount}</td>
                  <td>{user.systemPassword}</td>
                  <td>{user.passport}</td>
                  <td>{user.passportExpiryDate}</td>
                  <td>{user.phone}</td>
                  <td>{formatDate(user.expectedVisaSubmitDate)}</td>
                  <td>
                    {user.irpPdf ? (
                      <button type="button" className="custom-button" onClick={() => handleDownloadFile(user.irpPdf, 'irp.pdf')}>
                        下载IRP
                      </button>
                    ) : (
                      '无'
                    )}
                  </td>
                  <td>
                    {user.passportPdf ? (
                      <button type="button" className="custom-button" onClick={() => handleDownloadFile(user.passportPdf, 'passport.pdf')}>
                        下载护照
                      </button>
                    ) : (
                      '无'
                    )}
                  </td>
                  <td>{getStatusText(user.status)}</td>
                  <td>{user.paymentAmount}</td>
                  <td>{user.note || '无'}</td>
                  <td>
                  <div className="button-container2">
                    <button onClick={() => handleEditUser(user)}>编辑</button>
                  </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="button-container">
            <button onClick={handlePrevPage} disabled={page === 0}>上一页</button>
            <button onClick={handleNextPage} disabled={page === totalPages - 1}>下一页</button>
            <button onClick={handleExport}>导出</button> {/* 添加导出按钮 */}
          </div>
        </div>
      )}
    </div>
  );
};

const UserForm = ({ user, onSave, onCancel }) => {
  // 使用对象的解构和对象展开运算符设置默认值
  const [formData, setFormData] = useState({ ...user, bookingStatus: user ? user.bookingStatus : '未完成',
    expectTime: user.expectTime });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Name:', name);  // 应输出 'expectTime'
    console.log('Value:', value);  // 应输出选中的时间值
    // 使用对象展开运算符确保保留用户未编辑的字段
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // 获取Base64编码的部分
        setFormData(prevData => ({
          ...prevData,
          [name]: base64String
        }));
      };
      reader.readAsDataURL(file); // 读取文件并触发 onload 事件
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 将月份补齐为两位数
    const day = date.getDate().toString().padStart(2, '0'); // 将日期补齐为两位数
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit}>
        <label>
          国家:
          <input type="text" name="citizen" value={formData.citizen} onChange={handleChange} />
        </label>
        <label>
          地址:
          <input type="text" name="address" value={formData.address} onChange={handleChange} />
        </label>
        <label>
          社交账户:
          <input type="text" name="socialAccount" value={formData.socialAccount} onChange={handleChange} />
        </label>
        <label>
          邮箱账户:
          <input type="text" name="emailAccount" value={formData.emailAccount} onChange={handleChange} />
        </label>
        <label>
          邮箱密码:
          <input type="text" name="emailPassword" value={formData.emailPassword} onChange={handleChange} />
        </label>
        <label>
          系统账户:
          <input type="text" name="systemAccount" value={formData.systemAccount} onChange={handleChange} />
        </label>
        <label>
          系统密码:
          <input type="text" name="systemPassword" value={formData.systemPassword} onChange={handleChange} />
        </label>
        <label>
          护照号:
          <input type="text" name="passport" value={formData.passport} onChange={handleChange} />
        </label>
        <label>
          护照过期时间:
          <input type="date" name="passportExpiryDate" value={formatDate(formData.passportExpiryDate)} onChange={handleChange} />
        </label>
        <label>
          电话:
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
        </label>
        <label>
          期望递签日期:
          <input type="date" name="expectedVisaSubmitDate" value={formatDate(formData.expectedVisaSubmitDate)} onChange={handleChange} />
        </label>
        <label>
          irp文件:
          <input type="file" name="irpPdf" onChange={handleFileChange} />
        </label>
        <label>
          护照文件:
          <input type="file" name="passportPdf" onChange={handleFileChange} />
        </label>
        <label>
          订单状态:
          <select name="status" value={formData.status} onChange={handleChange}>
            {/* <option value="未完成">0</option>
            <option value="已完成">1</option>
            <option value="已取消">2</option> */}
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </label>
        <label>
          支付金额:
          <input type="text" name="paymentAmount" value={formData.paymentAmount} onChange={handleChange} />
        </label>
        <label>
          备注:
          <input type="text" name="note" value={formData.note} onChange={handleChange} />
        </label>
        <button className="custom-button" type="submit">保存</button>
        &nbsp;&nbsp;&nbsp; {/* 插入一个空格 */}
        <button className="custom-button" type="button" onClick={onCancel}>取消</button>
      </form>
    </div>
  );
};


export default ITAUserManagementPage;
